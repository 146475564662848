const colors = {
  tint: "#ea7e48",
  icon: "#fce6cf",
  tabIconDefault: "#e6e6e6",
  tabIconSelected: "#fff",

  grey: "#687076",
  primary: "#ee9f76",
  secondary: "#f8cda0",
  background: "#fcefe8",
  darkBackground: "#ee9f76",
  text: "#11181C",
  lightText: "#fff",
  inActive: "#fce6cf",
  danger: "#ed616f",
  success: "#00ff00",
  button: "#f8cda0",

  white: "#ffffff",

  new: "#c7c8cc",
  learning: "#f65c51",
  revise: "#f7b15c",
  learned: "#04ae3d",
};

export default colors;
