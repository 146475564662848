import React from "react";
import PropTypes from "prop-types";
import colors from "../colors";

const Container = ({ children, backgroundColor, ...props }) => {
  const containerStyle = {
    display: "flex",
    justifyContent: "center",
    backgroundColor: backgroundColor || colors.background,
    height: props["height"] || "100%",
    paddingBottom: "100px",
  };

  return (
    <div style={containerStyle}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          paddingTop: "50px",
        }}
      >
        {children}
      </div>
    </div>
  );
};

Container.propTypes = {
  children: PropTypes.node.isRequired,
  backgroundColor: PropTypes.string,
};

export default Container;
