// web/src/pages/Android.js
import React from "react";

function Android() {
  return (
    <div className="page-container">
      <h1>Android</h1>
      <p>Information about the Android app.</p>
    </div>
  );
}

export default Android;
