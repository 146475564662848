// web/src/pages/About.js
import React from "react";

function About() {
  return (
    <div className="page-container">
      <h1>About</h1>
      <p>This is the about page.</p>
    </div>
  );
}

export default About;
