import React from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../assets/logo-name.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Header.css";

function Header() {
  return (
    <header className="header">
      <div className="container d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center">
          <Link to="/">
            <img src={logo} alt="LingoCards Logo" className="logo" />
          </Link>
        </div>
        <nav>
          <ul className="nav">
            <li className="nav-item">
              <NavLink
                to="/"
                className="nav-link"
                activeClassName="nav-link-active"
              >
                Home
              </NavLink>
            </li>
            {/* <li className="nav-item">
              <NavLink
                to="/about"
                className="nav-link"
                activeClassName="nav-link-active"
              >
                About
              </NavLink>
            </li> */}
            <li className="nav-item">
              <NavLink
                to="/features"
                className="nav-link"
                activeClassName="nav-link-active"
              >
                Features
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/support"
                className="nav-link"
                activeClassName="nav-link-active"
              >
                Support
              </NavLink>
            </li>
            {/* <li className="nav-item">
              <NavLink
                to="/android"
                className="nav-link"
                activeClassName="nav-link-active"
              >
                Android
              </NavLink>
            </li> */}
          </ul>
        </nav>
      </div>
    </header>
  );
}

export default Header;
