import React from "react";
import "./Support.css";
import Container from "../components/Container";

function Support() {
  const handleEmail = () => {
    window.location.href = "mailto:support@lingo-cards.com";
  };

  return (
    <Container height={"100vh"}>
      <h1 className="support-title">Support</h1>
      <p className="support-description">
        Do you have any questions, feel free to contact us!
      </p>
      <p className="support-description">support@lingo-cards.com</p>
      <div className="support-button-row">
        <button className="support-button" onClick={handleEmail}>
          Email Support
        </button>
      </div>
    </Container>
  );
}

export default Support;
