import React from "react";
import Container from "../components/Container";
import "./Home.css";

function Features() {
  return (
    <Container>
      <div className="home-container">
        <div className="right-side extra-top-padding">
          <div className="col home-title">Learn Words At Your Own Pace</div>
          <div className="col home-text">
            <p>You can learn as many words as you want in a day.</p>
          </div>
        </div>
        <div className="left-side">
          <img src={require("../assets/android/home.png")} alt="Android" />
        </div>
      </div>

      <div className="home-container">
        <div className="left-side">
          <img src={require("../assets/android/Game Word.png")} alt="Android" />
        </div>
        <div className="right-side extra-top-padding">
          <div className="col home-title">Spaced Repetition</div>
          <div className="col home-text">
            <p>
              Lingo Cards use spaced repetition to help you learn words more
              effectively.
            </p>
          </div>
        </div>
      </div>

      <div className="home-container">
        <div className="right-side extra-top-padding">
          <div className="col home-title">
            Comprehensive Levels And Categories
          </div>
          <div className="col home-text">
            <p>
              Lingo Cards have a wide range of categories and levels to choose
              from.
            </p>
          </div>
        </div>
        <div className="left-side">
          <img src={require("../assets/android/vocab 2.png")} alt="Android" />
        </div>
      </div>

      <div className="home-container">
        <div className="left-side">
          <img src={require("../assets/android/word 1.png")} alt="Android" />
        </div>
        <div className="right-side extra-top-padding">
          <div className="col home-title">Word Information</div>
          <div className="col home-text">
            <p>
              You can get detailed information about the word you are learning.
            </p>
            <p>
              Each word comes with four sentences, with sounds and images to it.
            </p>
            <p>
              Just play sound and repeat the word to improve your pronunciation.
            </p>
          </div>
        </div>
      </div>

      <div className="home-container">
        <div className="right-side extra-top-padding">
          <div className="col home-title">Verb Conjugation</div>
          <div className="col home-text">
            <p>
              Lingo Cards provide verb conjugation for each verb in the present,
              past and perfekt tense.
            </p>
          </div>
        </div>
        <div className="left-side">
          <img
            src={require("../assets/android/conjugation.png")}
            alt="Android"
          />
        </div>
      </div>

      <div className="home-container">
        <div className="left-side">
          <img
            src={require("../assets/android/search word.png")}
            alt="Android"
          />
        </div>
        <div className="right-side extra-top-padding">
          <div className="col home-title">Search Words</div>
          <div className="col home-text">
            <p>
              You can search for any word you want to learn and start learning
              it.
            </p>
          </div>
        </div>
      </div>

      <div className="home-container">
        <div className="right-side extra-top-padding">
          <div className="col home-title">Stories</div>
          <div className="col home-text">
            <p>
              Lingo Cards allows you to read stories in German and English to
              learn new words in context.
            </p>
            <p>47 stories are available in German and English with audio</p>
          </div>
        </div>
        <div className="left-side">
          <img src={require("../assets/android/stories.png")} alt="Android" />
        </div>
      </div>

      <div className="home-container">
        <div className="left-side">
          <img src={require("../assets/android/story.png")} alt="Android" />
        </div>
        <div className="right-side extra-top-padding">
          <div className="col home-title">Story</div>
          <div className="col home-text">
            <p>
              You can read stories in German and English to learn words in
              context.
            </p>
          </div>
        </div>
      </div>

      <div className="home-container">
        <div className="right-side extra-top-padding">
          <div className="col home-title">Stories Vocab</div>
          <div className="col home-text">
            <p>
              Lingo Cards make it easy to choose words to learn from the stories
            </p>
          </div>
        </div>
        <div className="left-side">
          <img
            src={require("../assets/android/story vocab.png")}
            alt="Android"
          />
        </div>
      </div>

      <div className="home-container">
        <div className="left-side">
          <img src={require("../assets/android/settings.png")} alt="Android" />
        </div>
        <div className="right-side extra-top-padding">
          <div className="col home-title">Settings</div>
          <div className="col home-text">
            <p>
              You can change the settings of the app according to your
              preference.
            </p>
          </div>
        </div>
      </div>

      {/* Download option */}

      <div>
        <div style={{ marginTop: "50px", marginBottom: "100px" }}>
          <div className="col home-title" style={{ textAlign: "center" }}>
            Start Learning Now
          </div>
          <div className="col home-text" style={{ textAlign: "center" }}>
            <p>Download the App from Google Play Store.</p>
          </div>
          <div className="col home-text" style={{ textAlign: "center" }}>
            <img
              src={require("../assets/coming soon/image.png")}
              style={{ height: "70px" }}
              alt="Coming Soon"
            />
          </div>
        </div>
      </div>
    </Container>
  );
}

export default Features;
