import React from "react";
import Container from "../components/Container";
import "./Home.css";

function Home() {
  return (
    <Container>
      <div className="home-container">
        <div className="right-side extra-top-padding">
          <div className="col home-title">Lingo Cards</div>
          <div className="col home-text">
            <p>
              Fun and engaging way to learn German language or brush up on
              vocabulary and phrases. Each card in the deck features useful
              words, phrases, or cultural tidbits.
            </p>
            <img
              src={require("../assets/coming soon/image.png")}
              style={{ height: "70px" }}
              alt="Coming Soon"
            />
          </div>
        </div>
        <div className="left-side">
          <img src={require("../assets/android/home.png")} alt="Android" />
        </div>
      </div>
      <div className="home-container">
        <div className="left-side">
          <img src={require("../assets/android/word 1.png")} alt="Android" />
        </div>
        <div className="right-side extra-top-padding">
          <div className="col home-title">Self Learning Modules</div>
          <div className="col home-text">
            <p>1. Easy word selection from choosen levels and categories</p>
            <p>2. Spaced Repetition for effective learning</p>
            <p>3. Regular review for memorization</p>
            <p>4. Track your progress</p>
            <p>5. Vibrant graphics to understand words</p>
          </div>
        </div>
      </div>
      <div>
        <div style={{ marginTop: "50px", marginBottom: "100px" }}>
          <div className="col home-title" style={{ textAlign: "center" }}>
            Start Learning Now
          </div>
          <div className="col home-text" style={{ textAlign: "center" }}>
            <p>Download the App from Google Play Store.</p>
          </div>
          <div className="col home-text" style={{ textAlign: "center" }}>
            <img
              src={require("../assets/coming soon/image.png")}
              style={{ height: "70px" }}
              alt="Coming Soon"
            />
          </div>
        </div>
      </div>
    </Container>
  );
}

export default Home;
